const MESSAGE = `비정상적인 레퍼러가 감지되었어요!
이전 화면을 캡쳐한 뒤 지역사업실 프론트엔드 채널(#business-local-frontend)에서 @Jason.ha(제이슨)에게 전달 부탁드려요.
`;

export const checkInvalidReferrer = () => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const referrer = params.get("referrer");

  if (
    !referrer ||
    referrer === "" ||
    referrer === "null" ||
    referrer === "undefined"
  ) {
    alert(MESSAGE);
  }
};
